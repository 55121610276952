// ModalProvider.tsx
import React, { createContext, useState, ReactNode, useContext } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

interface ModalContextProps {
  isOpen: boolean;
  openModal: (content: ReactNode, shouldReloadOnClose?: boolean) => void;
  closeModal: () => void;
}

export const ModalContext = createContext<ModalContextProps>({
  isOpen: false,
  openModal: () => { },
  closeModal: () => { },
});

export const useModal = () => useContext(ModalContext);

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);
  const [shouldReloadOnClose, setShouldReloadOnClose] = useState(false);

  const openModal = (content: ReactNode, shouldReload: boolean = false) => {
    setModalContent(content);
    setShouldReloadOnClose(shouldReload);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setModalContent(null);
    navigator.vibrate(200);

    if (shouldReloadOnClose) {
      window.location.reload();
    }
  };

  return (
    <ModalContext.Provider value={{ isOpen, openModal, closeModal: handleClose }}>
      {children}
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-pink-500 opacity-50"
            onClick={handleClose}
          ></div>

          {/* Modal container */}
          <div className="bg-white rounded-lg z-10 mx-4 md:mx-auto w-full max-w-sm sm:max-w-md md:max-w-2xl p-4">
            {/* Close button */}
            <div className="flex justify-end">
              <AiFillCloseCircle
                className="cursor-pointer text-gray-700 hover:text-gray-900 transition duration-150 ease-in-out"
                size={30}
                onClick={handleClose}
              />
            </div>

            {/* Modal content */}
            <div className="p-4 max-h-[70vh] 2xl:max-h-full overflow-y-auto">
              {modalContent}
            </div>
          </div>
        </div>
      )}
    </ModalContext.Provider>
  );
};