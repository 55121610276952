import axios from 'axios';

export const api = axios.create({
  baseURL: 'https://blhr-server.onrender.com',
});

// export const api = axios.create({
//   baseURL: "http://localhost:8081",
// });
export const addNewServiceType = async (serviceTypeData: any) => {};
export const addStyle = async (newStyleData: FormData) => {};

export const updateStyle = async (updateData: FormData, id: number) => {};

export const getStyles = async () => {
  const response = await api.get('/api/styles/all');
  return response.data;
};

export const getServiceTypeStlyesAll = async () => {
  const response = await api.get("/api/service-type/service/styles");
  return response.data;
};

export const getBookings = async (token: string) => {
  const response = await api.get('/api/booking/all', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getAllServiceTypes = async () => {
  const response = await api.get('/api/service-type/service/all');
  return response.data;
};

export const getServiceTypeStyles = async (id: any) => {
  const response = await api.get(`api/service-type/styles/${id}`);
  return response.data;
};

export const register = async (userData: any) => {
  const response = await api.post('/auth/register', userData);
  return response.data;
};

export const login = async (userData: any) => {
  const response = await api.post('/auth/login', userData);
  return response.data;
};

export const deleteStylesById = async (id: number) => {};



export const getAllBookedServices = async (token: string) => {
  const response = await api.get('/api/booking/booked-services', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getAllUserBookings = async (token: string, id: any) => {
  const response = await api.get(`/api/users/bookings/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
