import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';

import { fetchStyles } from '../../../redux/reducers/stylesSlice';
import { useModal } from '../../ModalProvider';

import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store/store';
import axios from 'axios';
import { PropagateLoader } from 'react-spinners';
import { api } from '../../../api';
import UpdateServiceInfoMain from '../../forms/UpdateServiceInfoMain';
// import UpdateServiceInfoMain from '../../forms/UpdateServiceInfoMain';

interface Entry {
  styleId: number;
  serviceName: string;
  style: string;
  styleShortDescription: string;
  styleFullDetailsOfService: string;
  stylePrice: string;
  styleImage: File | null;
  styleDuration: string;
  styleDeposit: number;
  discount: number;
  value: number;
  availableDays: string[];
  createdAt: string;
}

interface TableProps {
  pageSize?: number;
}

const ServicesTable: React.FC<TableProps> = ({ pageSize = 8 }) => {
  const token = useSelector((state: RootState) => state.user.userData?.token);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
  const styles = useSelector((state: RootState) => state.styles.styles);
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(styles.length / pageSize);
  const [hoveredElement, setHoveredElement] = useState<number | null>(null);
  const { openModal, closeModal } = useModal();

  // const handleOpenUpdateModal = (style: Entry) => {
  //   return (
  //     <UpdateServiceInfoMain
  //       style={style}
  //       onUpdate={handleUpdateStyle}
  //       onCancel={closeModal}
  //     />
  //   )
  // };

  const handleOpenUpdate = (style: Entry) => {
    navigate(`/admin/update-service/${style.styleId}`, { state: { style } });
  }

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchStyles());
      setTableLoading(false);
    };

    fetchData();
  }, [dispatch]);

  const handleDeleteStyle = async (styleId: number) => {
    try {
      setLoading(true);

      const response = await api.delete(
        `/api/styles/delete/${styleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success('Style deleted successfully');
        dispatch(fetchStyles());
        // fetchStyles()
      } else {
        throw new Error('Failed to delete style');
      }
    } catch (error) {
      console.error('Error deleting style:', error);
      toast.error('Failed to delete style. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStyle = async (
    styleId: number,
    updatedStyleData: FormData
  ) => {
    console.log('Received updatedStyle data:', updatedStyleData);
    try {
      const response = await api.put(
        `/api/styles/update/${styleId}`,
        updatedStyleData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        toast.success('Style updated successfully');
        dispatch(fetchStyles());
        closeModal();
      } else {
        throw new Error('Failed to update style');
      }
    } catch (error) {
      console.error('Error updating style:', error);
      toast.error('Failed to update style. Please try again.');
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = styles.slice(startIndex, endIndex);

  const headers = [
    'Created At',
    'Image',
    'Service Name',
    'Deposit',
    'Style',
    'Price',
    'Duration',
    // 'Available Days',
    'Actions',
  ];

  const formatDate = (dateArray) => {
    if (!dateArray || !Array.isArray(dateArray)) return '';
    // Month is 0-based in JavaScript Date, so subtract 1 from the month
    const [year, month, day] = dateArray;
    return new Date(year, month - 1, day).toISOString().split('T')[0];
  };

  return (
    <section className="px-4 mx-auto w-full">
      <div className="mt-6 md:flex md:items-center sm:justify-between">
        <p className="bg-white text-2xl">List of Styles</p>

        <div className="flex justify-center items-center gap-3">
          <div className="flex gap-2">
            <NavLink to="/admin/create-service">
              <button
                type="button"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-primary text-white disabled:opacity-50 disabled:pointer-events-none"
              >
                Add Style
              </button>
            </NavLink>
            <div className="relative flex items-center mt-4 md:mt-0">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </span>
              <input
                type="text"
                placeholder="Search"
                className="block w-full py-1.5 pl-10 pr-3 text-gray-700 bg-white border border-brand-secondary rounded-lg md:w-80 placeholder-gray-400 focus:border-brand-secondary focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-6">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border md:rounded-lg">
              {tableLoading ? (
                <div className="flex justify-center items-center h-64">
                  <PropagateLoader
                    color="#FF0000"
                    loading={tableLoading}
                    size={15}
                  />
                </div>
              ) : (
                <>
                  {loading ? (
                    <div className="flex justify-center items-center h-64">
                      <PropagateLoader
                        color="#FF0000"
                        loading={loading}
                        size={15}
                      />
                    </div>
                  ) : (
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          {headers.map((header, index) => (
                            <th
                              key={index}
                              className="px-4 py-3.5 text-left text-sm font-semibold text-slate-950"
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-brand-primary divide-y divide-gray-200">
                        {currentData.map((style: Entry) => (
                          <tr key={style.styleId}>
                            <td className="px-4 py-4 text-sm text-white">
                              {formatDate(style.createdAt)}
                            </td>
                            <td className="px-4 py-4 text-sm text-white">
                              {style.styleImage && (
                                <img
                                  src={
                                    typeof style.styleImage === 'string'
                                      ? style.styleImage
                                      : URL.createObjectURL(style.styleImage)
                                  }
                                  alt={style.style}
                                  className="w-16 h-16 rounded"
                                />
                              )}
                            </td>
                            <td className="px-4 py-4 text-sm text-white">
                              {style.serviceName}
                            </td>
                            <td className="px-4 py-4 text-sm text-white">
                              {style.styleDeposit}
                            </td>
                            <td className="px-4 py-4 text-sm text-white">
                              {style.style}
                            </td>
                            <td className="px-4 py-4 text-sm text-white">
                              {style.stylePrice}
                            </td>
                            <td className="px-4 py-4 text-sm text-white">
                              {style.styleDuration}
                            </td>
                            {/* <td className="px-4 py-4 text-sm text-white">
                              {style.availableDays.join(', ')}
                            </td> */}
                            <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                              <div
                                className="relative inline-block"
                                onMouseEnter={() =>
                                  setHoveredElement(style.styleId)
                                }
                                onMouseLeave={() => setHoveredElement(null)}
                              >
                                <span className="cursor-pointer text-white">
                                  ...
                                </span>
                                {hoveredElement === style.styleId && (
                                  <div className="absolute top-full right-0 z-10 flex flex-col border w-24 bg-white">
                                    <button
                                      type="button"
                                      className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-teal-500 hover:bg-teal-100 hover:text-teal-800 disabled:opacity-50 disabled:pointer-events-none"
                                      onClick={() =>
                                        handleOpenUpdate(style)
                                      }
                                    >
                                      Edit
                                    </button>
                                    <button
                                      type="button"
                                      className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-500 hover:bg-red-100 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-red-800/30 dark:hover:text-red-400 dark:focus:outline-none"
                                      onClick={() =>
                                        handleDeleteStyle(style.styleId)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}

                  <div className="flex items-center justify-end mt-6 gap-2">
                    <button
                      onClick={() =>
                        handlePageChange(Math.max(1, currentPage - 1))
                      }
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Prev
                    </button>

                    {Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-4 py-2 text-sm font-medium ${
                          currentPage === index + 1
                            ? 'text-blue-600 bg-blue-100'
                            : 'text-gray-700 bg-white'
                        } border border-gray-300 rounded-md hover:bg-gray-50`}
                        disabled={currentPage === index + 1}
                      >
                        {index + 1}
                      </button>
                    ))}

                    <button
                      onClick={() =>
                        handlePageChange(Math.min(totalPages, currentPage + 1))
                      }
                      disabled={currentPage >= totalPages}
                      className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Next
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesTable;
