import { useState } from "react";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/store";
import { useLocation, useNavigate } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";

interface Booking {
    bookingId: number;
    bookingDate: string;
    bookingTime: string;
    serviceName: string;
    styleId: number;
    styleName: string;
    fullName: string;
    email: string;
    mobilePhone: string;
    styleDuration: string;
    styleDeposit: number;
    childName: string;
    policyAgreement: boolean;
    cancellationPolicy: boolean;
    discount: number;
    value: number;
    deposit: string;
    depositStatus: string;
    paymentStatus: string;
}

const UpdateBookingStatus: React.FC = () => {
    const location = useLocation();
    const booking = location.state.booking as Booking;

    const token = useSelector((state: RootState) => state.user.userData?.token);
    const userId = useSelector((state: RootState) => state.user.userData?.id);
    const [paymentStatus, setPaymentStatus] = useState(booking.paymentStatus);
    const [deposit, setDeposit] = useState(booking.deposit);
    const [loadingDeposit, setLoadingDeposit] = useState(false);
    const [loadingPayment, setLoadingPayment] = useState(false);
    const navigate = useNavigate();

    const updateDepositStatus = async () => {
        setLoadingDeposit(true);
        try {
            await api.put(
                `/api/booking/update/deposit/${booking.bookingId}`,
                deposit,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            toast.success('Deposit status updated successfully');
        } catch (error) {
            console.error('Error updating deposit status:', error);
            toast.error('Failed to update deposit status');
        } finally {
            setLoadingDeposit(false);
        }
    };

    const updatePaymentStatus = async () => {
        setLoadingPayment(true);
        try {
            await api.put(
                `/api/booking/update/payment/${booking.bookingId}`,
                paymentStatus,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            toast.success('Payment status updated successfully');
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Failed to update payment status';
            toast.error(errorMessage);
            console.error('Error updating payment status:', error);

        } finally {
            setLoadingPayment(false);
        }
    };

    const handlePaymentStatusUpdate = async () => {
        if (paymentStatus === 'CANCELLED') {
            await cancelPaymentStatus();
        } else {
            await updatePaymentStatus();
        }
    };

    const cancelPaymentStatus = async () => {
        setLoadingPayment(true);
        try {
            await api.put(
                `/api/users/cancel/${booking.bookingId}/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            toast.success('Service cancelled successfully');
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Failed to update payment status';
            toast.error(errorMessage);
            console.error('Error updating payment status:', error);
        } finally {
            setLoadingPayment(false);
        }
    };

    return (
        <div className="w-2/3 mx-auto py-8 px-20 sm:px-6 lg:px-8 bg-white shadow rounded-lg">
            <div className="flex justify-between p-2">
            <h2 className="text-3xl font-semibold text-center text-brand-primary mb-6">
                {booking ? "Update Booking Status" : "Add Booking"}
            </h2>

                <AiFillCloseCircle
                    className="cursor-pointer"
                    size={36}
                    onClick={() => navigate("/admin/bookings")}
                />

            </div>
            <div className="space-y-10 max-w-6xl">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                    <div>
                        <label htmlFor="deposit" className="block text-sm font-medium text-brand-primary">
                            Deposit Status
                        </label>
                        <select
                            id="deposit"
                            value={deposit}
                            onChange={(e) => setDeposit(e.target.value)}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                        >
                            <option value="PAID">PAID</option>
                            <option value="NOT_PAID">NOT PAID</option>
                        </select>
                        <button
                            type="button"
                            className="mt-2 w-full px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-primary hover:bg-brand-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary"
                            onClick={updateDepositStatus}
                            disabled={loadingDeposit}
                        >
                            {loadingDeposit ? "Updating..." : "Update Deposit Status"}
                        </button>
                    </div>
                    <div>
                        <label htmlFor="paymentStatus" className="block text-sm font-medium text-brand-primary">
                            Payment Status
                        </label>
                        <select
                            id="paymentStatus"
                            value={paymentStatus}
                            onChange={(e) => setPaymentStatus(e.target.value)}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                            disabled={deposit === "NOT_PAID" || loadingDeposit}
                        >
                            <option value="PENDING">PENDING</option>
                            <option value="CONFIRMED">CONFIRMED</option>
                            <option value="CANCELLED">CANCELLED</option>
                        </select>
                        <button
                            type="button"
                            className="mt-2 w-full px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-primary hover:bg-brand-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary"
                            onClick={handlePaymentStatusUpdate}
                            disabled={loadingPayment}
                        >
                            {loadingPayment ? "Updating..." : "Update Payment Status"}
                        </button>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label className="block text-sm font-medium text-brand-primary">Full Name</label>
                        <input
                            type="text"
                            value={booking.fullName}
                            readOnly
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-brand-primary">Email</label>
                        <input
                            type="text"
                            value={booking.email}
                            readOnly
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-brand-primary">Mobile Phone</label>
                        <input
                            type="text"
                            value={booking.mobilePhone}
                            readOnly
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-brand-primary">Service Name</label>
                        <input
                            type="text"
                            value={booking.serviceName}
                            readOnly
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-brand-primary">Booking Date</label>
                        <input
                            type="text"
                            value={booking.bookingDate}
                            readOnly
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-brand-primary">Booking Time</label>
                        <input
                            type="text"
                            value={booking.bookingTime}
                            readOnly
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-brand-primary">Style Name</label>
                        <input
                            type="text"
                            value={booking.styleName}
                            readOnly
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-brand-primary">Style Duration</label>
                        <input
                            type="text"
                            value={booking.styleDuration}
                            readOnly
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-brand-primary">Style Deposit</label>
                        <input
                            type="text"
                            value={`$${booking.styleDeposit}`}
                            readOnly
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-brand-primary">Child Name</label>
                        <input
                            type="text"
                            value={booking.childName}
                            readOnly
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-brand-primary">Discount</label>
                        <input
                            type="text"
                            value={`${booking.discount}%`}
                            readOnly
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-brand-primary">Value</label>
                        <input
                            type="text"
                            value={`$${booking.value}`}
                            readOnly
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-brand-primary focus:border-brand-primary sm:text-sm"
                        />
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        type="button"
                        className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-brand-primary bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary"
                        onClick={() => navigate("/admin/bookings")}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UpdateBookingStatus;