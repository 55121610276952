import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store/store';
import { fetchAllBookings } from '../../../redux/reducers/bookingSlice';
import { PropagateLoader } from 'react-spinners';
import axios from 'axios';
import { useModal } from '../../ModalProvider';
import ConfirmationModal from './ConfirmationModal';
import { toast } from 'react-toastify';
import { api } from '../../../api';
import Modal from '../../modal/Modal';
import { useNavigate } from 'react-router-dom';

interface Booking {
  bookingId: number;
  bookingDate: string; // Ensure this is in ISO format or a comparable format
  bookingTime: string;
  serviceName: string;
  styleId: number;
  styleName: string;
  fullName: string;
  email: string;
  mobilePhone: string;
  styleDuration: string;
  styleDeposit: number;
  childName: string;
  policyAgreement: boolean;
  cancellationPolicy: boolean;
  discount: number;
  value: number;
  deposit: string;
  depositStatus: string;
  paymentStatus: string;
  booked: boolean;
}

const BookingsTable: React.FC = () => {
  const token = useSelector((state: RootState) => state.user.userData?.token);
  const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
  const bookings = useSelector((state: RootState) => state.bookings.bookings);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [sortedBookings, setSortedBookings] = useState<Booking[]>([]); // State for sorted bookings
  const [editBookingId, setEditBookingId] = useState<number | null>(null);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const { openModal, closeModal } = useModal();
  const [depositStatus, setDepositStatus] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(null);
  const navigate = useNavigate();

  const totalPages = Math.ceil(sortedBookings.length / pageSize);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchAllBookings());
      setLoading(false);
    };
    fetchData();
    fetchDepositStatus();
    fetchPaymentStatus();
  }, [dispatch]);


  useEffect(() => {
    if (bookings && bookings.length > 0) {
      // Sort bookings by bookingId in descending order
      const sorted = [...bookings].sort((a, b) => {
        return new Date(b.bookingDate).getTime() - new Date(a.bookingDate).getTime()// Descending order
      });
      setSortedBookings(sorted);
    }
  }, [bookings]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleOpenUpdate = (booking: Booking) => {
    navigate(`/admin/update-booking/${booking.bookingId}`, { state: { booking } });
  };

  const fetchDepositStatus = async () => {
    try {
      const response = await api.get('/api/status/deposit-status', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setDepositStatus(response.data);
      }
    } catch (error) {
      console.error('Error fetching deposit status:', error);
    }
  };

  const fetchPaymentStatus = async () => {
    try {
      const response = await api.get('/api/status/payment-status', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setPaymentStatus(response.data);
      }
    } catch (error) {
      console.error('Error fetching payment status:', error);
    }
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentBookings = sortedBookings.slice(startIndex, endIndex);

  const headers = [
    'Booking Date',
    'Booking Time',
    'Style Name',
    'Phone',
    'Style Deposit',
    'Child Name',
    'Deposit Status',
    'Payment Status',
    'Actions',
  ];

  return (
    <section className="px-4 mx-auto w-full">
      <div className="mt-6 md:flex md:items-center sm:justify-between">
        <p className="bg-white text-2xl">List of Bookings</p>
      </div>
      <div className="flex flex-col mt-6">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border md:rounded-lg">
              {loading ? (
                <div className="flex justify-center items-center h-64">
                  <PropagateLoader color="#FF0000" loading={loading} size={15} />
                </div>
              ) : (
                <>
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        {headers.map((header, index) => (
                          <th
                            key={index}
                            className="px-4 py-3.5 text-left text-sm font-semibold text-slate-950"
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-brand-primary divide-y divide-gray-200">
                      {currentBookings.map((booking: Booking) => (
                        <tr key={booking.bookingId}>
                          <td className="px-4 py-4 text-sm text-white">{booking.bookingDate}</td>
                          <td className="px-4 py-4 text-sm text-white">{booking.bookingTime}</td>
                          <td className="px-4 py-4 text-sm text-white">{booking.styleName}</td>
                          <td className="px-4 py-4 text-sm text-white">{booking.mobilePhone}</td>
                          <td className="px-4 py-4 text-sm text-white">{booking.styleDeposit}</td>
                          <td className="px-4 py-4 text-sm text-white">{booking.childName}</td>
                          <td className="px-4 py-4 text-sm text-white">{booking.deposit}</td>
                          <td className="px-4 py-4 text-sm text-white">{booking.paymentStatus}</td>
                          <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                            <button
                              className="text-white"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenUpdate(booking);
                              }}
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    </table>
                  <div className="flex items-center justify-end mt-6 gap-2">
                    <button
                        onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Prev
                      </button>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-4 py-2 text-sm font-medium ${currentPage === index + 1
                          ? 'text-blue-600 bg-blue-100'
                          : 'text-gray-700 bg-white'
                          } border border-gray-300 rounded-md hover:bg-gray-50`}
                        disabled={currentPage === index + 1}
                      >
                        {index + 1}
                      </button>
                    ))}
                    <button
                        onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
                      disabled={currentPage >= totalPages}
                      className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Next
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {modalContent}
      </Modal>
    </section>
  );
};

export default BookingsTable;