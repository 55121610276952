import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { useModal } from '../../../components/ModalProvider';
import axios from 'axios';
import { api } from '../../../api';

interface AdminSideBarProps {}

const AdminSideBar = (props: AdminSideBarProps) => {
  const token = useSelector((state: RootState) => state.user.userData?.token);
  const { openModal, closeModal } = useModal();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const serviceTypeName = (event.currentTarget.elements.namedItem('service') as HTMLInputElement)?.value;

    setLoading(true);

    try {
      await createServiceType(serviceTypeName);
      toast.success('Service type created successfully!');
      closeModal();
    } catch (error) {
      console.error('Error creating service type:', error);
      toast.error('Failed to create service type. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const createServiceType = async (serviceName: string): Promise<void> => {
    const response = await api.post(
      '/api/service-type/add',
      { serviceName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status !== 200) {
      throw new Error('Failed to create service type');
    }
  };

  const handleOpenModal = () => {
    openModal(
      <div>
        <form onSubmit={handleFormSubmit}>
          <div className="grid gap-y-4">
            <div>
              <label className="block text-sm mb-2 ">Create a service</label>
              <div className="relative">
                <input
                  type="service"
                  name="service"
                  className="block w-full py-1.5 pl-10 pr-3 text-gray-700 bg-white border border-brand-secondary rounded-lg md:w-80 placeholder-gray-400  focus:border-brand-secondary  focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
                  placeholder="Enter service name"
                  required
                />
              </div>
            </div>

            <div className="flex justify-between">
              <button
                type="submit"
                className=" py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-green-400 text-white hover:bg-green-700 disabled:opacity-50 disabled:pointer-events-none "
              >
                {loading ? 'Please wait...' : 'Create'}
              </button>

              <button
                type="button"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-primary hover:bg-red-500 text-white  disabled:opacity-50 disabled:pointer-events-none"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const activeClassName = 'bg-brand-secondary text-white';
  return (
    <aside className="flex flex-col w-72 min-h-screen px-5 overflow-y-auto bg-white ">
      <div className="flex flex-col justify-between flex-1 mt-6">
        <nav className="-mx-3 space-y-6 ">
          <div className="space-y-3 text-xl">
            <button
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-primary text-white disabled:opacity-50 disabled:pointer-events-none hover:bg-[#c20c69] transition-colors duration-300"
              onClick={handleOpenModal}
            >
              Create a service
            </button>
            {/* <NavLink
              to="users"
              className={({ isActive }) =>
                `flex items-center px-3 py-2 text-brand-primary transition-colors duration-300 transform rounded-lg hover:bg-brand-secondary hover:text-white ${
                  isActive ? activeClassName : ''
                }`
              }
            >
              <span className="mx-2 font-medium">Users</span>
            </NavLink> */}
            <NavLink
              to="services"
              className={({ isActive }) =>
                `flex items-center px-3 py-2 text-brand-primary transition-colors duration-300 transform rounded-lg hover:bg-brand-secondary hover:text-white ${
                  isActive ? activeClassName : ''
                }`
              }
            >
              <span className="mx-2 font-medium">Styles</span>
            </NavLink>

            <NavLink
              to="service-types"
              className={({ isActive }) =>
                `flex items-center px-3 py-2 text-brand-primary transition-colors duration-300 transform rounded-lg hover:bg-brand-secondary hover:text-white ${isActive ? activeClassName : ''
                }`
              }
            >
              <span className="mx-2 font-medium">Services</span>
            </NavLink>

            {/* <NavLink
              to="uploads"
              className={({ isActive }) =>
                `flex items-center px-3 py-2 text-brand-primary transition-colors duration-300 transform rounded-lg hover:bg-brand-secondary hover:text-white ${
                  isActive ? activeClassName : ''
                }`
              }
            >
              <span className="mx-2 font-medium">Uploads</span>
            </NavLink> */}
            <NavLink
              to="bookings"
              className={({ isActive }) =>
                `flex items-center px-3 py-2 text-brand-primary transition-colors duration-300 transform rounded-lg hover:bg-brand-secondary hover:text-white ${
                  isActive ? activeClassName : ''
                }`
              }
            >
              <span className="mx-2 font-medium">Booking</span>
            </NavLink>


            <NavLink
              to="reviews"
              className={({ isActive }) =>
                `flex items-center px-3 py-2 text-brand-primary transition-colors duration-300 transform rounded-lg hover:bg-brand-secondary hover:text-white ${isActive ? activeClassName : ''
                }`
              }
            >
              <span className="mx-2 font-medium">Reviews</span>
            </NavLink>

            <NavLink
              to="users" 
              className={({ isActive }) =>
                `flex items-center px-3 py-2 text-brand-primary transition-colors duration-300 transform rounded-lg hover:bg-brand-secondary hover:text-white ${isActive ? activeClassName : ''
                }`
              }
            >
              <span className="mx-2 font-medium">Users</span>
            </NavLink>
          </div>
        </nav>
      </div>
    </aside>
  );
};
export default AdminSideBar;
