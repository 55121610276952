import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';
import { NavLink } from 'react-router-dom';
import { api } from '../../../api';
import { toast } from 'react-toastify';
import { PropagateLoader } from 'react-spinners';

interface ServiceType {
    serviceId: number;
    serviceName: string;
}

interface TableProps {
    pageSize?: number;
}

const ServiceTypes: React.FC<TableProps> = ({ pageSize = 8 }) => {
    const token = useSelector((state: RootState) => state.user.userData?.token);
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(true);
    const [services, setServices] = useState<ServiceType[]>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(services.length / pageSize);
    const [hoveredElement, setHoveredElement] = useState<number | null>(null);

    // Modal state
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState<ServiceType | null>(null);
    const [updatedServiceName, setUpdatedServiceName] = useState("");

    const handleOpenUpdate = (service: ServiceType) => {
        setSelectedService(service);
        setUpdatedServiceName(service.serviceName);
        setIsUpdateModalOpen(true);
    };

    const handleUpdateSubmit = async () => {
        if (!selectedService) return;

        try {
            const response = await api.put(
                `/api/service-type/update/${selectedService.serviceId}`,
                {
                    serviceId: selectedService.serviceId,
                    serviceName: updatedServiceName
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                toast.success("Service updated successfully");
                // Update and sort the services list locally
                setServices(prevServices =>
                    prevServices.map(service =>
                        service.serviceId === selectedService.serviceId
                            ? { ...service, serviceName: updatedServiceName }
                            : service
                    ).sort((a, b) => a.serviceId - b.serviceId)
                );
                setIsUpdateModalOpen(false);
            } else {
                throw new Error("Failed to update service");
            }
        } catch (error) {
            console.error("Error updating service:", error);
            toast.error("Failed to update service");
        }
    };

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await api.get("/api/service-type/service/all", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                // Sort services by serviceId before setting state
                const sortedServices = response.data.sort((a: ServiceType, b: ServiceType) =>
                    a.serviceId - b.serviceId
                );
                setServices(sortedServices);
                setTableLoading(false);
            } catch (error) {
                console.error("Error fetching services:", error);
                toast.error("Failed to fetch services");
                setTableLoading(false);
            }
        };

        fetchServices();
    }, [token]);

    const handleDeleteService = async (serviceId: number) => {
        try {
            setLoading(true);

            const response = await api.delete(
                `/api/service-type/delete/${serviceId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                toast.success("Service deleted successfully");
                setServices(services.filter(service => service.serviceId !== serviceId));
            } else {
                throw new Error("Failed to delete service");
            }
        } catch (error) {
            console.error("Error deleting service:", error);
            toast.error("Failed to delete service. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentData = services.slice(startIndex, endIndex);

    const headers = ["Service Name", "Action"];

    return (
        <section className="px-4 mx-auto w-full">
            <div className="mt-6 md:flex md:items-center sm:justify-between">
                <p className="bg-white text-2xl">List of Services</p>

                {/* <div className="flex justify-center items-center gap-3">
                    <div className="flex gap-2">
                        <NavLink to="/admin/create-service">
                            <button
                                type="button"
                                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-primary text-white disabled:opacity-50 disabled:pointer-events-none"
                            >
                                Add Service
                            </button>
                        </NavLink>
                    </div>
                </div> */}
            </div>

            <div className="flex flex-col mt-6">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden border md:rounded-lg">
                            {tableLoading ? (
                                <div className="flex justify-center items-center h-64">
                                    <PropagateLoader
                                        color="#FF0000"
                                        loading={tableLoading}
                                        size={15}
                                    />
                                </div>
                            ) : (
                                <>
                                    {loading ? (
                                        <div className="flex justify-center items-center h-64">
                                            <PropagateLoader
                                                color="#FF0000"
                                                loading={loading}
                                                size={15}
                                            />
                                        </div>
                                    ) : (
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    {headers.map((header, index) => (
                                                        <th
                                                            key={index}
                                                            className="px-4 py-3.5 text-left text-sm font-semibold text-slate-950"
                                                        >
                                                            {header}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-brand-primary divide-y divide-gray-200">
                                                {currentData.map((service: ServiceType) => (
                                                    <tr key={service.serviceId}>

                                                        <td className="px-4 py-4 text-sm text-white">
                                                            {service.serviceName}
                                                        </td>
                                                        <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                                            <div
                                                                className="relative inline-block"
                                                                onMouseEnter={() =>
                                                                    setHoveredElement(service.serviceId)
                                                                }
                                                                onMouseLeave={() => setHoveredElement(null)}
                                                            >
                                                                <span className="cursor-pointer text-white">
                                                                    ...
                                                                </span>
                                                                {hoveredElement === service.serviceId && (
                                                                    <div className="absolute top-full right-0 z-10 flex flex-col border w-24 bg-white">
                                                                        <button
                                                                            type="button"
                                                                            className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-teal-500 hover:bg-teal-100 hover:text-teal-800 disabled:opacity-50 disabled:pointer-events-none"
                                                                            onClick={() => handleOpenUpdate(service)}
                                                                        >
                                                                            Edit
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-500 hover:bg-red-100 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-red-800/30 dark:hover:text-red-400 dark:focus:outline-none"
                                                                            onClick={() =>
                                                                                handleDeleteService(service.serviceId)
                                                                            }
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}

                                    <div className="flex items-center justify-end mt-6 gap-2">
                                        <button
                                            onClick={() =>
                                                handlePageChange(Math.max(1, currentPage - 1))
                                            }
                                            disabled={currentPage === 1}
                                            className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            Prev
                                        </button>

                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <button
                                                key={index}
                                                onClick={() => handlePageChange(index + 1)}
                                                className={`px-4 py-2 text-sm font-medium ${currentPage === index + 1
                                                    ? "text-blue-600 bg-blue-100"
                                                    : "text-gray-700 bg-white"
                                                    } border border-gray-300 rounded-md hover:bg-gray-50`}
                                                disabled={currentPage === index + 1}
                                            >
                                                {index + 1}
                                            </button>
                                        ))}

                                        <button
                                            onClick={() =>
                                                handlePageChange(Math.min(totalPages, currentPage + 1))
                                            }
                                            disabled={currentPage >= totalPages}
                                            className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Simple Modal */}
            {isUpdateModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                        <h2 className="text-xl font-semibold mb-4">Update Service</h2>
                        <div className="mb-4">
                            <label htmlFor="serviceName" className="block text-sm font-medium text-gray-700 mb-1">
                                Service Name
                            </label>
                            <input
                                id="serviceName"
                                type="text"
                                value={updatedServiceName}
                                onChange={(e) => setUpdatedServiceName(e.target.value)}
                                className="w-full p-2 border rounded focus:ring-1 focus:ring-brand-primary focus:border-brand-primary"
                            />
                        </div>
                        <div className="flex justify-end gap-4">
                            <button
                                onClick={() => setIsUpdateModalOpen(false)}
                                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleUpdateSubmit}
                                className="px-4 py-2 text-sm font-medium text-white bg-brand-primary rounded-md hover:bg-opacity-90"
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default ServiceTypes;