import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { PropagateLoader } from 'react-spinners';
import { RootState } from '../../redux/store/store';
import { fetchAllUserBookings } from '../../redux/reducers/userSlice';
import axios from 'axios';
import RescheduleBookingDetails from '../../components/Bookings/RescheduleBookingDetails';
import { api } from '../../api';
import Modal from '../../components/modal/Modal';
import ViewBookingDetails from '../../components/Bookings/ViewBookingDetails';
import { toast } from 'react-toastify';

const UserBookingsTable: React.FC = () => {
  const token = useSelector((state: RootState) => state.user.userData?.token);
  const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const userBookings = useSelector(
    (state: RootState) => state.userServices.userBookings
  );
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [selectedBooking, setSelectedBooking] = useState<any>(null);
  const [selectedService, setSelectedService] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoveredElement, setHoveredElement] = useState<number | null>(null);
  const [showRescheduleDetails, setShowRescheduleDetails] =
    useState<boolean>(false);

  const totalPages = Math.ceil(userBookings.length / pageSize);
  const [sortedBookings, setSortedBookings] = useState([]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchAllUserBookings());
      setLoading(false);
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (userBookings.length > 0) {
      const sorted = [...userBookings].sort((a, b) =>
        new Date(b.bookingDate).getTime() - new Date(a.bookingDate).getTime()
      );
      setSortedBookings(sorted);
    }
  }, [userBookings]);

  const getStyleById = async (styleId: number) => {
    setLoading(true);
    try {
      const response = await api.get(
        `/api/styles/${styleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setSelectedService(response.data);
    } catch (error) {
      console.error('Error fetching style:', error);
      setSelectedService(null);
    } finally {
      setLoading(false);
    }
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentUserBookings = sortedBookings.slice(startIndex, endIndex);

  const headers = [
    'Booking Date',
    'Booking Time',
    'Service Name',
    'Style Name',
    'Style Duration',
    'Deposit Status',
    'Payment Status',
    'Actions',
  ];



 const handleView = async (booking: any) => {
    setSelectedBooking(booking);
    setIsModalOpen(true);
  };


  const handleReschedule = async (booking: any) => {
    setSelectedBooking(booking);
    await getStyleById(booking.styleId);
    setShowRescheduleDetails(true);
  };




  const handleCancel = async (bookingId: number) => {
    try {
      await api.delete(
        `/api/booking/delete/${bookingId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      dispatch(fetchAllUserBookings());
      toast.success('Booking cancelled successfully');
    } catch (error) {
      console.error('Error cancelling booking:', error);
    }
  };

  

  if (showRescheduleDetails && selectedBooking && selectedService) {
    return (
      <RescheduleBookingDetails
        service={selectedService}
        bookingId={selectedBooking.bookingId}
        onClose={() => setShowRescheduleDetails(false)}
      />
    );
  }

  const now = new Date();
  return (
    <section
      className="px-4 mx-auto w-full"
      onClick={() => setHoveredElement(null)}
    >
      <div className="mt-6 md:flex md:items-center sm:justify-between">
        <p className="bg-white text-2xl">Appointments</p>
      </div>

      <div className="flex flex-col mt-6 ">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border md:rounded-lg">
              {loading ? (
                <div className="flex justify-center items-center h-64">
                  <PropagateLoader
                    color="#FF0000"
                    loading={loading}
                    size={15}
                  />
                </div>
              ) : (
                <>
                    <table className="min-w-full divide-y divide-gray-200 pb-10">
                    <thead className="bg-gray-50">
                      <tr>
                        {headers.map((header, index) => (
                          <th
                            key={index}
                            className="px-4 py-3.5 text-left text-sm font-semibold text-slate-950"
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                      <tbody className="bg-brand-primary divide-y divide-gray-200 mt-5 ">
                        {currentUserBookings.map((booking: any) => {
                          const createdAt = new Date(booking.createdAt);
                          const isLessThan48Hours = (now.getTime() - createdAt.getTime()) < (48 * 60 * 60 * 1000);

                          return (
                            <tr key={booking.bookingId}>
                              <td className="px-4 py-4 text-sm text-white">
                                {booking.bookingDate}
                              </td>
                              <td className="px-4 py-4 text-sm text-white">
                                {booking.bookingTime}
                              </td>
                              <td className="px-4 py-4 text-sm text-white">
                                {booking.serviceName}
                              </td>
                              <td className="px-4 py-4 text-sm text-white">
                                {booking.styleName}
                              </td>
                              <td className="px-4 py-4 text-sm text-white">
                                {booking.styleDuration}
                              </td>
                              <td className="px-4 py-4 text-sm text-white">
                                {booking.deposit}
                              </td>
                              <td className="px-4 py-4 text-sm text-white">
                                {booking.paymentStatus}
                              </td>
                              <td className="px-4 py-4 text-sm text-white">
                                <div className="relative inline-block z-[5000] ">
                                  <span
                                    className="cursor-pointer text-white"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setHoveredElement(booking.bookingId);
                                    }}
                                  >
                                    ...
                                  </span>
                                  {hoveredElement === booking.bookingId && (
                                    <div className="absolute top-full right-0 flex flex-col border w-24 z-50 bg-white">
                                      <button
                                        type="button"
                                        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-500 hover:bg-blue-100 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none"
                                        onClick={() => handleView(booking)}
                                      >
                                        View
                                      </button>
                                      {booking.deposit === "NOT_PAID" && (
                                        <>
                                          <button
                                            type="button"
                                            className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-teal-500 hover:bg-teal-100 hover:text-teal-800 disabled:opacity-50 disabled:pointer-events-none"
                                            onClick={() => handleReschedule(booking)}
                                            disabled={!isLessThan48Hours}
                                          >
                                            Reschedule
                                          </button>
                                          <button
                                            type="button"
                                            className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-500 hover:bg-red-100 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-red-800/30 dark:hover:text-red-400 dark:focus:outline-none"
                                            onClick={() => handleCancel(booking.bookingId)}
                                            disabled={!isLessThan48Hours}
                                          >
                                            Cancel
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                    <div className="flex items-center justify-end mt-28 gap-2">
                    <button
                      onClick={() =>
                        handlePageChange(Math.max(1, currentPage - 1))
                      }
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Prev
                    </button>

                    {Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-4 py-2 text-sm font-medium ${
                          currentPage === index + 1
                            ? 'text-blue-600 bg-blue-100'
                            : 'text-gray-700 bg-white'
                        } border border-gray-300 rounded-md hover:bg-gray-50`}
                        disabled={currentPage === index + 1}
                      >
                        {index + 1}
                      </button>
                    ))}

                    <button
                      onClick={() =>
                        handlePageChange(Math.min(totalPages, currentPage + 1))
                      }
                      disabled={currentPage >= totalPages}
                      className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Next
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>


      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          {selectedBooking && (
            <ViewBookingDetails bookingDetails={selectedBooking} />
          )}
        </Modal>
      )}
    </section>
  );
};

export default UserBookingsTable;
