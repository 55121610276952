import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store/store';
import { AiFillCloseCircle } from 'react-icons/ai';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { createBooking } from '../../redux/reducers/createBookingSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { ThunkDispatch } from '@reduxjs/toolkit';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface BookingModalContent2Props {
  selectedDate: string;
  selectedTime: string;
  service: {
    serviceName: string;
    styleId: number;
    styleName: string;
    style: string;
    styleDuration: string;
    styleDeposit: string;
    discount: number;
    value: number;
  };
  onFormSubmit?: (data: any) => void;
  onBookingCreated?: (bookingDetails: any) => void;
}

const BookingModalContent2: React.FC<BookingModalContent2Props> = ({
  selectedDate,
  selectedTime,
  service,
  onBookingCreated,
}) => {
  const [phone, setPhone] = useState('');
 
  const [phoneValid, setPhoneValid] = useState(true);
  const [childName, setChildName] = useState('');
  const [policyAgreement, setPolicyAgreement] = useState(false);
  const [cancellationPolicy, setCancellationPolicy] = useState(false);
  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const user = useSelector((state: RootState) => state.user.userData);
  const { status: createBookingStatus } = useSelector(
    (state: RootState) => state.createBooking
  );

  const isFormValid = () => {
    return (
      phoneValid &&
      phone.length > 0 &&
      childName.trim() !== '' &&
      policyAgreement &&
      cancellationPolicy
    );
  };
  const isLoading = createBookingStatus === 'loading';

  // Default values
  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`;
  const email = user?.email || '';


  const validatePhoneNumber = (phoneNumber: string, country: any) => {
    const digitsOnly = phoneNumber.replace(/\D/g, '');

    // For Canada (+1), we expect 11 digits in total (including country code)
    if (country.dialCode === '1') {
      return digitsOnly.length === 11 && digitsOnly.startsWith('1');
    }

    // For other countries, you might want to adjust this logic
    return digitsOnly.length === 10;
  };

  const handlePhoneChange = (value: string, country: any) => {
    const phoneWithPlus = value.startsWith('+') ? value : `+${value}`;
    setPhone(phoneWithPlus);
    const isValid = validatePhoneNumber(phoneWithPlus, country);
    setPhoneValid(isValid);
  };


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isFormValid()) {
      toast.error('Please fill in all required fields and agree to the policies');
      return;
    }

    if (!phoneValid) {
      toast.error('Please enter a valid phone number');
      return;
    }

    const form = e.currentTarget;
    const formData = {
      userId: user?.id,
      bookingDate: selectedDate,
      bookingTime: selectedTime,
      serviceName: service.serviceName,
      styleId: service.styleId,
      styleName: service.style,
      styleDuration: service.styleDuration,
      styleDeposit: service.styleDeposit,
      discount: service.discount,
      value: service.value,
      fullName: (form.elements.namedItem('fullName') as HTMLInputElement).value,
      email: (form.elements.namedItem('email') as HTMLInputElement).value,
      mobilePhone: phone.startsWith('+') ? phone : `+${phone}`,
      childName: childName,
      policyAgreement: policyAgreement,
      cancellationPolicy: cancellationPolicy,
    };

    try {
      const bookingDetails = await dispatch(createBooking(formData)).then(
        unwrapResult
      );
      onBookingCreated(bookingDetails);
      

    } catch (error) {
      console.error('Failed to create booking:', error);
    }
  };

  return (
    <div className="bg-[#F7F9F2] p-4 md:p-6">
      <div className="max-w-xl md:max-w-2xl lg:max-w-3xl mx-auto">
        <p className="text-sm md:text-base lg:text-lg mb-4 text-justify md:text-left">
          Kindly ensure punctuality for your appointment. While there is a
          15-minute grace period, it's important not to take advantage of this
          time. If you anticipate running late, please send a courtesy text to
          notify me.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col md:flex-row md:space-x-4">
                <input
                  type="text"
                  name="fullName"
                  disabled
                  placeholder="Name"
                  defaultValue={fullName}
                  className="w-full md:w-1/2 px-4 py-2 bg-[#FADADF] border border-[#A020F0] rounded focus:outline-none focus:ring-2 focus:ring-[#A020F0] placeholder-[#A020F0] placeholder:text-sm"
                />
                <input
                  type="email"
                  name="email"
                  disabled
                  placeholder="Email"
                  defaultValue={email}
                  className="w-full md:w-1/2 px-4 py-2 mt-4 md:mt-0 bg-[#FADADF] border border-[#A020F0] rounded focus:outline-none focus:ring-2 focus:ring-[#A020F0] placeholder-[#A020F0] placeholder:text-sm"
                />
              </div>
              <div className="mb-4">
                {/* Label with an asterisk for mandatory field */}
                <label htmlFor="childName" className="text-sm md:text-base font-semibold text-[#A020F0]">
                  Child's Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="childName"
                  name="childName"
                  value={childName}
                  onChange={(e) => setChildName(e.target.value)}
                  placeholder="Enter Child's Name"
                  className="w-full px-4 py-2 mt-1 bg-[#FADADF] border border-[#A020F0] rounded focus:outline-none focus:ring-2 focus:ring-[#A020F0] placeholder-[#A020F0] placeholder:text-sm"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="phone" className="text-sm md:text-base font-semibold text-[#A020F0]">
                  Mobile Number <span className="text-red-500">*</span>
                </label>
                <PhoneInput
                  country={'ca'}
                  value={phone}
                  onChange={handlePhoneChange}
                  inputProps={{
                    name: 'mobilePhone',
                    required: true,
                  }}
                  onlyCountries={['ca']}
                  isValid={phoneValid}
                  containerClass="w-full"
                  inputClass="w-full py-2 pl-12 pr-4 bg-[#FADADF] border border-[#A020F0] rounded focus:outline-none focus:ring-2 focus:ring-[#A020F0] text-[#A020F0] placeholder-[#A020F0] placeholder:text-sm"
                  buttonClass="bg-[#FADADF] border-r-0 border-[#A020F0] rounded-l"
                  dropdownClass="bg-[#FADADF] text-[#A020F0]"
                  enableSearch={false}
                  disableSearchIcon={true}
                  countryCodeEditable={false}
                />
                {!phoneValid && (
                  <p className="text-red-500 text-sm mt-1">Please enter a valid phone number</p>
                )}
              </div>
            </div>
          </div>
          <p className="text-sm md:text-base lg:text-lg text-justify md:text-left">
            Please note, appointment will only be confirmed upon receipt of a deposit.
            Deposits must be paid at the time of booking without exceptions.
            <br />
            Kindly pay a <em className='font-bold'>${service.styleDeposit}</em> deposit fee. Please make payment to {' '}
            <em className='font-bold'>beenahairatrading@gmail.com</em>, with your child's name and hair service as a remark.
          </p>

          <div className="flex items-start mb-6">
            <input
              type="checkbox"
              name="policyAgreement"
              checked={policyAgreement}
              onChange={(e) => setPolicyAgreement(e.target.checked)}
              className="mt-1 mr-2 text-[#A020F0] focus:ring-pink-500 border-[#A020F0]"
              required
            />
            <p className="text-sm md:text-base lg:text-lg text-justify md:text-left">
              <strong>I have READ and AGREE to policies listed.</strong>
              <br />
              I acknowledge that booking fees can be transferred once within the current year and are non-refundable, except in cases of genuine emergencies or if the stylist, Precious, cancels the appointment.
            </p>
          </div>
          <div className="flex items-start mb-6">
            <input
              type="checkbox"
              name="cancellationPolicy"
              checked={cancellationPolicy}
              onChange={(e) => setCancellationPolicy(e.target.checked)}
              className="mt-1 mr-2 text-[#A020F0] focus:ring-pink-500 border-[#A020F0]"
              required
            />
            <p className="text-sm md:text-base lg:text-lg text-justify md:text-left">
              <strong>I agree to the cancellation policy.</strong>
            </p>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className={`py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm md:text-base font-semibold rounded-lg border border-transparent text-white ${isLoading || !isFormValid()
                ? 'bg-pink-300 cursor-not-allowed'
                : 'bg-pink-500 hover:bg-pink-600'
                } focus:outline-none focus:ring-2 focus:ring-pink-600 w-full`}
              disabled={isLoading || !isFormValid()}
            >
              {isLoading ? (
                <div
                  className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-white rounded-full"
                  role="status"
                  aria-label="loading"
                >
                  <span className="sr-only">Please wait...</span>
                </div>
              ) : (
                'Book Now'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>


  );
};

export default BookingModalContent2;
