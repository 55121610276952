// BookingDetails.tsx
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useLocation } from 'react-router-dom';
import Footer from '../Footer';
import BookingPolicy from './BookingPolicy';
import { useModal } from '../ModalProvider';
import BookingModalContent1 from './BookingModalContent1';
import BookingModalContent2 from './BookingModalContent2';
import BookingModalContent3 from './BookingModalContent3';
import { RootState } from '../../redux/store/store';
import { fetchAllBookedServices } from '../../redux/reducers/bookedServicesSlice';

interface BookedDate {
  date: string;
  styleId: number;
}

interface BookedTime {
  time: string;
  styleId: number;
}

const BookingDetails: React.FC = () => {
  const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
  const allBookedServices = useSelector(
    (state: RootState) => state.bookedServices.bookedServices
  );
  const { openModal, closeModal } = useModal();
  const location = useLocation();
  const { service } = location.state || {};

  const [loading, setLoading] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [formData, setFormData] = useState<any | null>(null);
  const [bookedDates, setBookedDates] = useState<BookedDate[]>([]);
  const [bookedTimes, setBookedTimes] = useState<BookedTime[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(fetchAllBookedServices());
      setLoading(false);
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (allBookedServices.length > 0 && service) {
      const bookedDatesArray: BookedDate[] = allBookedServices
        .filter((bookedService) => bookedService.styleId === service.styleId)
        .map((bookedService) => ({
          date: bookedService.bookingDate,
          styleId: bookedService.styleId,
        }));
      const bookedTimesArray: BookedTime[] = allBookedServices
        .filter((bookedService) => bookedService.styleId === service.styleId)
        .map((bookedService) => ({
          time: bookedService.bookingTime,
          styleId: bookedService.styleId,
        }));
      setBookedDates(bookedDatesArray);
      setBookedTimes(bookedTimesArray);
    }
  }, [allBookedServices, service]);

  if (!service) {
    return <div>No service selected.</div>;
  }


  const handleOpenModal = async () => {
    if (loading) return;

    setIsFetching(true);
    await dispatch(fetchAllBookedServices());
    setIsFetching(false);

    openModal(
      <BookingModalContent1
        dayTimeMapping={service.dayTimeMapping}
        bookedServices={allBookedServices}
        serviceId={service.styleId}
        onDateTimeSelected={(date, time) => {
          setSelectedDate(date);
          setSelectedTime(time);
          openModal(
            <BookingModalContent2
              selectedDate={date}
              selectedTime={time}
              service={service}
              onBookingCreated={(bookingDetails) => {
                // Pass true as second argument to openModal to enable reload on close
                openModal(
                  <BookingModalContent3 bookingDetails={bookingDetails} />,
                  true // This modal will trigger a reload when closed
                );
              }}
            />
          );
        }}
      />
    );
  };

  return (
    <section className="">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="hover:animate-background rounded-xl bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 p-0.5 shadow-xl transition hover:bg-[length:400%_400%] hover:shadow-sm hover:[animation-duration:_4s]">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8 items-center bg-white rounded-xl">
            <div className="relative mb-8 lg:mb-0">
              <div className="aspect-w-1 aspect-h-1 rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105">
                <img
                  alt={service.serviceName}
                  className="w-full h-full object-cover object-center"
                  src={service.styleImage}
                />
              </div>
            </div>
            {/* Product Details */}
            <div className="px-4 sm:px-0">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 mb-4">
                {service.style}
              </h1>
              <div className="mt-6">
                <p className="text-xl text-gray-600 leading-relaxed">
                  {service.styleFullDetailsOfService}
                </p>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-8">
                <h2 className="text-lg font-semibold text-gray-900 mb-4">
                  Additional Details
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <div className="text-lg font-semibold">Deposit Fee</div>
                    <div className="text-gray-600">${service.styleDeposit}</div>
                  </div>
                  <div>
                    <div className="text-lg font-semibold">Duration</div>
                    <div className="text-gray-600">{service.styleDuration}</div>
                  </div>
                </div>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-8">
                <div className="flex flex-col sm:flex-row items-center justify-between p-3">
                  <span className="text-4xl font-extrabold text-gray-900 mb-4 sm:mb-0">
                    ${service.stylePrice}
                  </span>
                  <button
                    className="bg-pink-500 border border-transparent rounded-md py-4 px-10 flex items-center justify-center text-base font-medium text-white hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
                    onClick={handleOpenModal}
                    disabled={loading}
                  >
                    {loading ? (
                      <div
                        className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-red-600 rounded-full"
                        role="status"
                        aria-label="loading"
                      ></div>
                    ) : (
                      'Book Now'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookingPolicy />
      <div className="mt-16">
        <Footer />
      </div>
    </section>
  );
};

export default BookingDetails;
